<template src="./healthCenter24h.html"></template>
<script>
export default {
  data(){
    return{
      number: null,
      numberTwo: false,
      tel: null,
      planServices: null,
    }
  },
};
</script>
<style lang="scss" src="./healthCenter24h.scss">
</style>